<template>
  <div class="limit-wrap">
    <div class="limit-padding" :style="{ background: bg }">
      <div class="limit-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'limit-wrap',

    props: {
      bg: {
        type: String,
        default: 'transparent'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .limit-wrap {
    height: 100%;

    .limit-padding {
      max-width: 1000px;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      background: #fff;
    }

    .limit-content {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .limit-wrap {
      .limit-padding {
        max-width: 800px;
        // padding: 0 40px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .limit-wrap {
      .limit-padding {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }
</style>
