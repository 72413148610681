<template>
  <div class="round-card" v-if="mainMsg || subMsg">
    <div class="main-msg" v-if="mainMsg">{{ mainMsg }}</div>
    <div class="sub-msg" v-if="subMsg">{{ subMsg }}</div>
  </div>
</template>

<script>
  export default {
    name: 'round-card',

    props: {
      mainMsg: {
        type: String,
        default: ''
      },
      subMsg: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .round-card {
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    backdrop-filter: blur(4px);
    padding: 16px 20px;

    .main-msg {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
      padding-bottom: 16px;
    }

    .sub-msg {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
  }
</style>
