<template>
  <div class="lang-switch">
    <a class="lang-txt" :class="{ selected: i18n.locale === 'zh_CN' }" href="javascript:;" @click="changeLang('zh_CN')">
      简
    </a>
    <span class="v-line" href="javascript:;">|</span>
    <a class="lang-txt" :class="{ selected: i18n.locale === 'zh_HK' }" href="javascript:;" @click="changeLang('zh_HK')">
      繁
    </a>
  </div>
</template>

<script>
  import i18n from '@/language'
  export default {
    name: 'lang-switch',

    data() {
      return {
        i18n
      }
    },

    methods: {
      changeLang(lang) {
        if (i18n.locale === lang) return
        i18n.locale = lang
        window.location.reload()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lang-switch {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    .lang-txt {
      font-size: 16px;
      font-weight: 400;
      color: #999;

      &.selected {
        color: #333;
      }
    }

    .v-line {
      font-size: 14px;
      font-weight: 500;
      color: #cacaca;
      margin: 0 10px;
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .lang-switch {
      .lang-txt {
        font-size: 13px;
      }

      .v-line {
        font-size: 13px;
        margin: 0 8px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .lang-switch {
      .lang-txt {
        font-size: 16px;
      }

      .v-line {
        font-size: 14px;
        margin: 0 8px;
        margin-top: 2px;
      }
    }
  }
</style>
