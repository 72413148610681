<template>
  <div class="quotes-card" v-if="msg">
    <img class="quotes-icon start" src="@/assets/images/icp/quotes_left.webp" />
    <img class="quotes-icon end" src="@/assets/images/icp/quotes_right.webp" />
    <div class="card-msg">{{ msg }}</div>
  </div>
</template>

<script>
  export default {
    name: 'quotes-card',

    props: {
      msg: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .quotes-card {
    position: relative;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    backdrop-filter: blur(4px);
    padding: 12px 64px;
    width: 434px;

    .quotes-icon {
      position: absolute;
      width: 32px;
      height: 26px;

      &.start {
        top: 12px;
        left: 16px;
      }
      &.end {
        bottom: 12px;
        right: 16px;
      }
    }

    .card-msg {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .quotes-card {
      border-radius: 6px;
      padding: 10px 51px;
      width: 347px;

      .quotes-icon {
        position: absolute;
        width: 25px;
        height: 20px;

        &.start {
          left: 12px;
        }
        &.end {
          right: 12px;
        }
      }

      .card-msg {
        font-size: 12px;
        line-height: 29px;
      }
    }
  }
</style>
