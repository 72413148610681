<template>
  <div class="index-page">
    <page-header />
    <div class="page-content">
      <page-banner />
      <company-info />
    </div>
    <page-footer />
  </div>
</template>

<script>
  import PageHeader from './components/page-header.vue'
  import PageBanner from './components/page-banner.vue'
  import CompanyInfo from './components/company-info.vue'
  import PageFooter from './components/page-footer.vue'

  export default {
    name: 'index-page',
    components: {
      PageHeader,
      PageBanner,
      CompanyInfo,
      PageFooter
    }
  }
</script>

<style lang="scss" scoped>
  .index-page {
    .page-content {
      min-height: calc(100vh - 80px - 80px);
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .index-page {
      .page-content {
        min-height: calc(100vh - 64px - 64px);
      }
    }
  }

  @media screen and (max-width: 750px) {
    .index-page {
      .page-content {
        min-height: calc(100vh - 56px - 76px);
      }
    }
  }
</style>
