<template>
  <div class="page-header">
    <page-header-logo />
    <lang-switch />
  </div>
</template>

<script>
  import PageHeaderLogo from './page-header-logo.vue'
  import LangSwitch from '@/components/LangSwitch'
  export default {
    name: 'page-header',
    components: {
      PageHeaderLogo,
      LangSwitch
    }
  }
</script>

<style lang="scss" scoped>
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 80px;
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .page-header {
      height: 64px;
      padding: 0 40px;
    }
  }

  @media screen and (max-width: 750px) {
    .page-header {
      height: 56px;
      padding: 0 20px;
      border: none;
    }
  }
</style>
