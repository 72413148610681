<template>
  <div class="page-footer">
    <div class="footer-content">
      <a class="icp-link" href="https://beian.miit.gov.cn/" target="“_blank”">{{ icpNum }}</a>
      <span> ｜ Copyright © {{ $t('icp.companyName') }}</span>
    </div>

    <div class="footer-content-h5">
      <div>Copyright © {{ $t('icp.companyName') }}</div>
      <a class="icp-link" href="https://beian.miit.gov.cn/" target="“_blank”">{{ icpNum }}</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'page-footer',

    computed: {
      icpNum() {
        console.log(process.env)
        return process.env.VUE_APP_ICP_NUM
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-footer {
    width: 100%;
    height: 80px;
    background: #424242;

    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;

    .footer-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icp-link {
      color: #999999;
      cursor: pointer;
    }

    .footer-content-h5 {
      width: 100%;
      height: 100%;
      display: none;

      .icp-link {
        color: #999999;
        cursor: pointer;
        padding-top: 4px;
      }
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .page-footer {
      height: 64px;
      font-size: 10px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 750px) {
    .page-footer {
      height: 76px;
      background: #4d4d4d;
      font-size: 13px;
      line-height: 20px;

      .footer-content {
        display: none;
      }

      .footer-content-h5 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 20px;
      }
    }
  }
</style>
