<template>
  <div class="company-info">
    <limit-wrap>
      <div class="info-content">
        <div class="info-left">
          <p class="title">{{ $t('aboutUs.address') }}</p>
          <p class="row">{{ $t('aboutUs.addressItemTitle2') }}</p>
          <p class="row">{{ $t('aboutUs.addressItemDesc2') }}</p>
        </div>
        <div class="info-right">
          <img src="@/assets/images/about/img_map_1.png" />
        </div>
      </div>
    </limit-wrap>
  </div>
</template>

<script>
  import LimitWrap from '@/components/LimitWrap'

  export default {
    name: 'company-info',

    components: {
      LimitWrap
    }
  }
</script>

<style lang="scss" scoped>
  .company-info {
    background: #f9f9f9;
    height: 390px;

    .info-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .info-left {
      .title {
        font-size: 30px;
        font-weight: 600;
        color: #121212;
        line-height: 42px;
        padding-bottom: 36px;
      }
      .row {
        font-size: 18px;
        font-weight: 400;
        color: #121212;
        line-height: 36px;
        padding-top: 4px;
      }
    }

    .info-right {
      width: 400px;
      height: 240px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .company-info {
      height: 312px;

      .info-left {
        .title {
          font-size: 24px;
          line-height: 33px;
          padding-bottom: 30px;
        }
        .row {
          font-size: 14px;
          line-height: 29px;
          padding-top: 3px;
        }
      }

      .info-right {
        width: 320px;
        height: 193px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .company-info {
      height: auto;
      padding-top: 74px;
      padding-bottom: 40px;

      .info-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      .info-left {
        text-align: center;

        .title {
          font-size: 18px;
          line-height: 25px;
          padding-bottom: 8px;
        }
        .row {
          font-size: 14px;
          line-height: 20px;
          padding-top: 8px;
        }
      }

      .info-right {
        padding-top: 32px;
        width: 100%;
        height: auto;
      }
    }
  }
</style>
