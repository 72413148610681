<template>
  <div class="page-banner">
    <limit-wrap>
      <div class="banner-content">
        <div class="banner-text">
          <div class="text-row">{{ $t('icp.companyName') }}</div>
          <div class="text-row">
            <span>{{ $t('icp.introText1') }}</span>
            <a>{{ $t('icp.introText2') }}</a>
            <span>{{ $t('icp.introText3') }}</span>
            <a>{{ $t('icp.introText4') }}</a>
            <span>{{ $t('icp.introText5') }}</span>
          </div>
        </div>
        <quotes-card :msg="$t('icp.companyDesc')" />
      </div>
      <div class="banner-content-h5">
        <round-card
          :mainMsg="`${$t('icp.companyName')}${$t('icp.introText1')}${$t('icp.introText2')}${$t('icp.introText3')}${$t(
            'icp.introText4'
          )}${$t('icp.introText5')}。`"
          :subMsg="$t('icp.companyDesc')"
        />
      </div>
    </limit-wrap>
  </div>
</template>

<script>
  import LimitWrap from '@/components/LimitWrap'
  import QuotesCard from './quotes-card.vue'
  import RoundCard from './round-card.vue'

  export default {
    name: 'page-banner',

    components: {
      LimitWrap,
      QuotesCard,
      RoundCard
    }
  }
</script>

<style lang="scss" scoped>
  .page-banner {
    width: 100%;
    height: 550px;
    background: #f4f6f9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-image: url('../../../assets/images/icp/bg_pc_big.webp');
    position: relative;

    .banner-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .banner-content-h5 {
      display: none;
    }

    .banner-text {
      padding-bottom: 30px;

      .text-row {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        line-height: 50px;

        > a {
          color: #1890ff;
        }
      }
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .page-banner {
      height: 440px;

      .banner-text {
        padding-bottom: 24px;

        .text-row {
          font-size: 22px;
          line-height: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .page-banner {
      background-size: 100% auto;
      background-image: url('../../../assets/images/icp/bg_h5.webp');
      height: 200px;

      .banner-content {
        display: none;
      }

      .banner-content-h5 {
        display: block;
        padding-top: 70px;
      }
    }
  }
</style>
