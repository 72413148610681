import Vue from 'vue'
import App from './index.vue'
import '@/common/index.js'
import { validateInitPage } from '@/utils/validateInit-page.js'
import i18n from '@/language'
import langHelper from '@/language/helper'

validateInitPage() // 这里还可以链式then做一些权限拦截
  .then((pageName, pageParams) => {
    Vue.prototype.$pageName = pageName
    Vue.prototype.$pageParams = pageParams
    document.title = i18n.t('router.winbull')
    console.log(Vue)
    new Vue({
      name: 'index',
      i18n,
      watch: {
        '$i18n.locale': {
          immediate: true,
          handler(val) {
            const key = langHelper.langKeyformat(val, '_', '-')
            langHelper.setLocale(key)
          }
        }
      },
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(e => {
    console.log(e)
  })
